import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import { Layout, SEO } from "../../components/structure";
import { Content, Hero } from "../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";
import Logo from "../../components/structure/Header/Logo";

const ProcessorPage = () => {
  const Headerlogo = useStaticQuery(graphql`
    query {
      plusgrade_logo: file(base: { eq: "Plusgrade-Primary-Dark.png" }) {
        childImageSharp {
          fixed(width: 277, height: 46, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const ProcessorList = styled.div`
    ${font.imports.sherika}
    padding-top: 180px;
    width: 95%;
    max-width: 1400px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .logo-con {
      width: 277px;
      height: 46px;
    }

    h2 {
      color: ${brand.colors.pg_primary_dark};
      font-family: ${font.family.sherika};
      font-size: 56px;
      font-weight: 700;
      padding-top: 40px;
    }

    p {
      font-family: ${font.family.sherika};
      color: ${brand.colors.pg_primary_dark};
    }

    table {
      width: 100%;
      max-width: 1100px;
      min-width: 400px;
      overflow: scroll;
      margin: auto;

      @media only screen and ${mq.maxMd} {
        display: none;
      }

      thead {
        tr {
          td {
            background-color: ${brand.colors.pg_secondary_dark};
            color: #fff;
            text-align: left;
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            color: ${brand.colors.pg_primary_dark};
            text-align: left;
            padding: 10px;

            a {
              color: ${brand.colors.pg_blue};
              text-decoration: underline;
              curosr: pointer;
            }

            &:nth-of-type(3) {
              width: 155px;
            }
            &:nth-of-type(4) {
              width: 200px;
            }
          }
        }
      }
    }

    .mobile-table {
      display: none;

      .provider-con {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 1px solid ${brand.colors.pg_secondary_dark};
        padding: 10px 0;

        span {
          color: ${brand.colors.pg_primary_dark};

          a {
            color: ${brand.colors.pg_blue};
            text-decoration: underline;
            curosr: pointer;
          }
        }
      }

      @media only screen and ${mq.maxMd} {
        display: block;
      }
    }
  `;

  return (
    <Layout>
      <SEO
        title="Sub-processors | Plusgrade"
        description={"Plusgrade Sub-processors"}
      />
      <Content whiteBG noMargin>
        <ProcessorList>
          <div className="logo-con">
            <GatsbyImage
              fixed={Headerlogo["plusgrade_logo"].childImageSharp.fixed}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <h2>Plusgrade Sub-processors</h2>
          <p>Current as of January 28, 2025</p>

          <table>
            <thead>
              <tr>
                <td>Third Party Service/ Vendor</td>
                <td>Purpose</td>
                <td>Entity Country</td>
                <td>Website</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Amazon Cloud</td>
                <td>Infrastructure Provider</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://aws.amazon.com/compliance/gdpr-center/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Atlassian</td>
                <td>Productivity</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.atlassian.com/blog/announcements/atlassian-and-gdpr-our-commitment-to-data-privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Google</td>
                <td>Productivity & Cloud Infrastructure Provider</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://cloud.google.com/security/gdpr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Meta (Custom Audiences)</td>
                <td>Online Advertising</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.facebook.com/privacy/policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Netsuite</td>
                <td>Billing and Customer Support</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.oracle.com/applications/gdpr/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Nexmo</td>
                <td>Communications Infrastructure Provider</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.nexmo.com/gdpr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Nuvei</td>
                <td>Payment Service Provider</td>
                <td>Canada</td>
                <td>
                  {" "}
                  <a
                    href="https://nuvei.com/privacy-notice/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Paysafe Group Limited</td>
                <td>Payment Service Provider</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.paysafe.com/legal-and-compliance/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Points</td>
                <td>Service Delivery</td>
                <td>Canada</td>
                <td>
                  <a
                    href="https://www.points.com/legal/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Salesforce</td>
                <td>Productivity</td>
                <td>United States</td>
                <td>
                  {" "}
                  <a
                    href="https://www.salesforce.com/privacy/overview/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Slack</td>
                <td>Productivity</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://slack.com/gdpr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Twilio Sendgrid</td>
                <td>Email Infrastructure Provider</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://sendgrid.com/resource/general-data-protection-regulation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>

              <tr>
                <td>Zen Desk</td>
                <td>Customer Support</td>
                <td>United States</td>
                <td>
                  <a
                    href="https://www.zendesk.com/company/privacy-and-data-protection/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Privacy Policy
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mobile-table">
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Amazon Cloud
              </span>
              <span>
                <strong>Purpose: </strong>Infrastructure Provider
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://aws.amazon.com/compliance/gdpr-center/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Atlassian
              </span>
              <span>
                <strong>Purpose: </strong>Productivity
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.atlassian.com/blog/announcements/atlassian-and-gdpr-our-commitment-to-data-privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Dropbox
              </span>
              <span>
                <strong>Purpose: </strong>Productivity
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.dropbox.com/security/GDPR/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Freshworks
              </span>
              <span>
                <strong>Purpose: </strong>Customer Support
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.freshworks.com/privacy/gdpr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>

            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Google
              </span>
              <span>
                <strong>Purpose: </strong>Productivity & Cloud Infrastructure
                Provider
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://cloud.google.com/security/gdpr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Netsuite
              </span>
              <span>
                <strong>Purpose: </strong>Billing and Customer Support
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.oracle.com/applications/gdpr/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Nexmo
              </span>
              <span>
                <strong>Purpose: </strong>Communications Infrastructure Provider
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.nexmo.com/gdpr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Paysafe Group
                Limited
              </span>
              <span>
                <strong>Purpose: </strong>Payment Service Provider
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.paysafe.com/legal-and-compliance/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Twilio Sendgrid
              </span>
              <span>
                <strong>Purpose: </strong>Email Infrastructure Provider
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://sendgrid.com/resource/general-data-protection-regulation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>

            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Slack
              </span>
              <span>
                <strong>Purpose: </strong>Productivity
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://slack.com/gdpr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Zen Desk
              </span>
              <span>
                <strong>Purpose: </strong>Customer Support
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.zendesk.com/company/privacy-and-data-protection/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>

            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Nuvei
              </span>
              <span>
                <strong>Purpose: </strong>Payment Service Provider
              </span>
              <span>
                <strong>Entity Country: </strong>Canada
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://nuvei.com/privacy-notice/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Third Party Service/ Vendor: </strong>Salesforce
              </span>
              <span>
                <strong>Purpose: </strong>Productivity
              </span>
              <span>
                <strong>Entity Country: </strong>United States
              </span>
              <span>
                <strong>Website: </strong>
                <a
                  href="https://www.salesforce.com/privacy/overview/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </ProcessorList>
      </Content>
    </Layout>
  );
};

export default ProcessorPage;
